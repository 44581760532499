
import { mdiCheckboxMarkedOutline, mdiCheckboxBlankOutline  } from '@mdi/js';

import sanitize from '~/mixins/sanitize.js'

export default {
  mixins: [sanitize],
  data() {
    return {
      mdiCheckboxMarkedOutline, 
      mdiCheckboxBlankOutline,
      spinner: false,
      confirmation: false,
      snackMessage: '',
      snackbar: false,
      selected: [true, true, true],
      valid: true,
      name: '',
      email: '',
      nameRules: [
        (n) => !!n || 'Name is required',
        (n) => (n && !!n.trim()) || 'Provide a valid Name',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'Provide a valid E-mail',
      ],
    }
  },
  methods: {
    async FreeResourcesSignUp() {
      this.spinner = true

      const request = {
        Name: this.sanitize(this.name),
        Email: this.email,
        MicrosoftTeamsShortcuts: true,
        GuideToVirtualFacilitation: true,
        GuideToChooseRightDatabaseForYou: true,
      }

      await setTimeout(() => {
        this.$kaiden.post('Website/free-ressources', request).then((res) => {
          if (res.status === 200) {
            this.snackbar = false
            this.spinner = false
            this.confirmation = true
            this.$gtm.push({
                event: 'free_resources',
                currency: 'CAD',
                value: 30
              })
          }
        })

        // console.log(request)
        // this.spinner = false
        // this.confirmation = true
      }, 2500)
    },

    clear() {
      this.name = ''
      this.email = ''
      this.$refs.form.resetValidation()
      this.selected[0] = true
      this.selected[1] = true
      this.selected[2] = true
    },
    async validateForm() {
      const element = document.getElementsByClassName('newsletter-gray-area')
      await window.scrollTo({
        top: element[0].offsetTop + 80,
        left: 0,
        behavior: 'smooth',
      })

      if (this.$refs.form.validate()) {
        await this.FreeResourcesSignUp()
        this.clear()
      } else {
        this.snackbar = true
        this.snackMessage = 'Please add proper contact information'
      }
    },
    snackState() {
      this.snackbar = false
    },
  },
}
