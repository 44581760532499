import { render, staticRenderFns } from "./FreeResources.vue?vue&type=template&id=48a144b0&scoped=true&"
import script from "./FreeResources.vue?vue&type=script&lang=js&"
export * from "./FreeResources.vue?vue&type=script&lang=js&"
import style0 from "./FreeResources.vue?vue&type=style&index=0&id=48a144b0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a144b0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UISnackBar: require('C:/home/site/wwwroot/components/UI/SnackBar.vue').default,UISpinnerLarge: require('C:/home/site/wwwroot/components/UI/SpinnerLarge.vue').default})
