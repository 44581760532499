
import { mdiCloseBox } from '@mdi/js';
export default {
  props: {
    state: {
      default: false,
      type: Boolean,
    },
    snackMessage: {
      default: 'Something went wrong. Please Try again later.',
      type: String,
    },
  },
  data() {
    return {
      mdiCloseBox,
      snackbar: false,
    }
  },
  computed: {},
  watch: {
    async state(newState) {
      this.snackbar = newState     
      await setTimeout(this.Close, 10000)
      
    },
  },
  methods: {
    Close() {
      this.snackbar = false
      this.$emit('snack-state')
    },
  },
}
